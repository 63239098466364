import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { alex_companies } from "../pages/select-company/moscow";
import { variants_alex, variants_diod } from "../pages/welcome";

import { geoAPI } from "../shared/api";
import { TCompany } from "../types/city.type";
import { BaseStore } from "./lib/baseStore.type";
import { parseCities } from "./lib/parseCities";

class CitiesStore implements BaseStore {
  @observable companies: TCompany[] = [];
  @observable companiesByCities: Record<string, TCompany[]> = {};
  @observable isLoading = true;

  constructor() {
    makeObservable(this);
    this.fetchCities();
  }

  @action
  async fetchCities() {
    try {
      this.setLoading(true);
      const response = await geoAPI.getCompanies();
      /* const responseKto = (await geoAPI.getCompaniesKto()).data.data.filter(x => x.id === 146058); */
      runInAction(() => {
        this.companies = [...response.data.data];
        this.companiesByCities = parseCities(this.companies);
      });
    } catch (error) {
      console.log("fetchCities", error);
    } finally {
      this.setLoading(false);
    }
  }

  @computed
  get cities() {
    return Object.keys(this.companiesByCities);
  }

  getCompaniesByCityName(city: string) {
    return this.companiesByCities[city] || [];
  }

  @action
  setLoading(value: boolean) {
    this.isLoading = value;
  }

  @action
  resetStore() {
    this.companies = [];
  }
}

export const citiesStore = new CitiesStore();
