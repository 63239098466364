import React from "react";
import { Link } from "react-router-dom";

import { observer } from "mobx-react-lite";

import { Box } from "@chakra-ui/layout";

import { useStep } from "../../shared/hook/useStep";
import { AppTemplate } from "../../shared/ui/AppTemplate";
import { CardContent } from "./CardContent";
import { CardTitle } from "./CardTitle";

import { Card } from "antd";

export const SelectZonePage = observer(() => {
  return <></>;
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(false);
  const loading = false;

  const { nextStep } = useStep();

  // const { registerStore } = useStore();
  // useEffect(() => {
  //   const fetchService = async () => {
  //     const company = registerStore.selectedCompany || { id: 313958 };
  //     try {
  //       if (company && registerStore.selectedService === null) {
  //         setLoading(true);
  //         await sleep(1000);
  //         const { data } = await serviceAPI.getServiceById({ companyId: company.id });
  //         const service = data.data;

  //         if (service) registerStore.setService(service);

  //         // setError(false);
  //       }
  //     } catch (error) {
  //       console.log("fetchService", error);
  //       // setError(true);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchService();
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AppTemplate headerTitle='Выберите зону'>
      <Box p={5} overflowY='auto'>
        <Link to={nextStep.route}>
          <Card loading={loading} hoverable title={<CardTitle loading={loading} />}>
            <CardContent />
          </Card>
        </Link>
      </Box>
    </AppTemplate>
  );
});
