import React from "react";
// import { FaInstagram /* FaWallet */ } from "react-icons/fa";

import { Box } from "@chakra-ui/layout";
import { Button as ChakraButton } from "@chakra-ui/react";
import { Result } from "antd";
import { useStore } from "../../stores";
import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { Routes } from "../../shared/enums/routes";
import { Link } from "react-router-dom";

export const SuccessPage = () => {
  const { registerStore } = useStore();

  React.useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyAODnA4EfNRBeGgA4BNoEb3PYJ5TFcgAlY",
      authDomain: "missis-laser.firebaseapp.com",
      projectId: "missis-laser",
      storageBucket: "missis-laser.appspot.com",
      messagingSenderId: "514174044584",
      appId: "1:514174044584:web:3a7576b39c7a543c6ab59d",
      measurementId: "G-QTNN569ZLV",
    };

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    logEvent(analytics, "success_appointment");
  }, [registerStore]);

  return (
    <Box height='100vh' display='flex' alignItems='center' justifyContent='center'>
      <Result
        status='success'
        title='Успешно'
        subTitle='Спасибо за заявку, позже мы с Вами свяжемся'
        extra={
          <div style={{ marginTop: 16 }}>
            <Link to={`${Routes.Preparation}`}>
              <ChakraButton size='sm' colorScheme='green' /* leftIcon={<FaInstagram />} */>
                Как подготовится к процедуре?
              </ChakraButton>
            </Link>
          </div>
        }
      />
    </Box>
  );
};
